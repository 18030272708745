<template>
  <q-page class="">
    <div class="q-pa-md text-h6 text-center">
      Halaman Data Pendaftar
    </div>
    <div class="q-pa-md">
      <q-markup-table flat square dense>
      <!-- table head -->
        <thead class="bg-blue-grey-14 text-white">
          <tr>
            <th style="width:10px;">No</th>
            <th class="text-left">Nama</th>
            <th class="text-left">email/username</th>
            <th class="text-left">Bukti Transfer</th>
            <th class="text-center">Verivikasi</th>
            <th class="text-center">Detail Formulir</th>
          </tr>
        </thead>
        <!-- table body  -->
        <tbody v-if="listPendaftar.length" class="bg-blue-grey-1">
          <tr v-for="(d, i) in listPendaftar" :key="d.id">
            <td class="text-left">{{ i + 1 }}</td>
            <td class="text-left">{{ d.nama }}</td>
            <td class="text-left">{{ d.username }}</td>
            <td class="text-left transfer">{{ d.file_transfer }} <q-btn flat no-caps color="deep-orange-8" @click="selected = d; verifikasi = true" label="View" /> </td>
            <td class="text-center"> <q-checkbox disable v-model="d.status" /> </td>
            <td class="text-center"> <q-btn dense flat no-caps type="a" target="_blank" label="View" color="deep-orange-8" @click="goToFormFinal(d.nisn)"  /> </td>
          </tr>
        </tbody>
        <tbody v-else class="bg-green-1">
          <tr>
            <td class="text-center text-grey" colspan="99">
              tidak ada data
            </td>
          </tr>
        </tbody>
      </q-markup-table>
    </div>
    <q-dialog v-model="verifikasi" persistent>
      <q-card>
        <q-card-section >
          <img
            :src="'../data/pendaftar/' + selected.file_transfer"
            alt="file_trf" style="width: 400px;"
          />
        </q-card-section>
        <q-card-section class="text-subtitle1 q-py-sm text-center">
          Nominal Harus Bayar: <b> {{selected.nominal_bayar}} </b>
        </q-card-section>
        <q-card-section class="row justify-evenly">
          <q-btn unelevated  style="width: 120px;" color="grey" label="Kembali" @click="verifikasi = false" no-caps/>
          <q-btn outline  style="width: 120px;" color="light-blue-6" label="Tidak Sesuai" @click="updateStatus('N')" no-caps/>
          <q-btn unelevated style="width: 120px;color:white;" color="light-blue-6" label="Sesuai" @click="updateStatus('Y')" no-caps />
        </q-card-section>
      </q-card>
    </q-dialog>
  </q-page>
</template>

<style>
</style>

<script>
let token = localStorage.getItem("smp-afbs")
export default {
  name: 'HelloWorld',
  data(){
    return{
      listPendaftar: [],
      selected: {},
      verifikasi: false,


    }
  },
  mounted(){

    this.$http.get("/list_pendaftar", {})
      .then(result => {
        console.log(result.data);
        this.listPendaftar = result.data
        for(var i = 0; i< this.listPendaftar.length; i++){
          if(this.listPendaftar[i].status == 'N')
            this.listPendaftar[i].status = false
          else
            this.listPendaftar[i].status = true
        }
      })
  },
  methods:{
    updateStatus(val){
      if(val == 'Y')
        this.selected.status = true
      else
        this.selected.status = false

      this.$http.put( "/update_status/" + this.selected.id + '/' + val ,{
          headers: { Authorization: "Bearer " + token}
        })
        .then((result) => {

          this.verifikasi = false
          
        });
    },
    goToFormFinal(val){
      let routeData = this.$router.resolve({name: 'form-final', params:{nisn: val}});
      window.open(routeData.href, '_blank');
    }

  }
}
</script>
